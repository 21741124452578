import React, { Component } from 'react';
import PropTypes from "prop-types";
import AuthService from '../shared/AuthService';

export default class LoginForm extends Component {
    constructor(props) {
        super(props);
        
        // Set up state
        this.state = {
            username: '',
            password: '',
            error: ''
        };
        
        // Set up event handlers
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    static propTypes = {
        authService: PropTypes.instanceOf(AuthService).isRequired,
        onLoginSuccess: PropTypes.func.isRequired
    };

    handleUsernameChange(event) {
        const username = event.target.value;
        this.setState(() => ({ username: username }));
    }

    handlePasswordChange(event) {
        const password = event.target.value;
        this.setState(() => ({ password: password }));
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState(() => ({ error: '' }));

        const succesHandler = () => {
            this.setState(() => ({ username: '', password: '', error: '' }));
            this.props.onLoginSuccess();
        };
        const errorHandler = (error) => this.setState(() => ({ error: error }));
        this.props.authService.login(this.state.username, this.state.password, succesHandler, errorHandler);
    }

    render() {  
        return (
            <form className="login-form" onSubmit={this.handleSubmit}>
                <div className="error">{this.state.error}</div> 
                <input
                    type="text"
                    onChange={this.handleUsernameChange}
                    placeholder="Username"
                    value={this.state.username}
                />
                <input
                    type="password"
                    onChange={this.handlePasswordChange}
                    placeholder="Password"
                    value={this.state.password}
                />
                <button type="submit">Login</button>
            </form>
        );
    }
}