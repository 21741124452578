import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Route, Redirect} from 'react-router-dom'
import AuthService from '../shared/AuthService';

export default class PrivateRoute extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: true,
      isAuthenticated: false
    }
  }

  static propTypes = {
    children: PropTypes.object.isRequired,
    authService: PropTypes.instanceOf(AuthService).isRequired,
  };

  componentDidMount() {
    this.props.authService.isAuthenticated()
      .then((isAuthenticated) => {
        this.setState({
          loading: false,
          isAuthenticated
        })
      })
  }

  render() {
    const { children, ...rest } = this.props
    return (
      <Route {...rest} render={ routeProps =>
          this.state.loading ? (
            <div>Loading...</div>
          ) : (
            this.state.isAuthenticated ? (
              children
            ) : (
              <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />
            )
          )
        } />
    )
  }
}