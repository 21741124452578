import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class QueryForm extends Component {
    constructor(props) {
        super(props);
        
        // Set up state
        this.state = {
            query: '',
        };

        // Set up event handlers
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
    }

    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    };

    handleQueryChange(event) {
        const query = event.target.value;
        this.setState(() => ({ query: query }));
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSubmit(this.state.query);
    }

    render() {
        return (
            <form className="query-form" onSubmit={this.handleSubmit}>
                <i className="fa fa-search" />
                <input
                    onChange={this.handleQueryChange}
                    type="text"
                    placeholder="What are you looking for?"
                    value={this.state.query}
                />
                <button type="submit">Search</button>
            </form>
        );
    }
}