import React, { Component } from 'react';
import QueryForm from './components/QueryForm';
import Results from './components/Results';
import {search} from './shared/API';
import LoginForm from './components/LoginForm'
import {HashRouter as Router, Switch, Route} from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import AuthService from "./shared/AuthService";
import Loader from './components/Loader';

const authService = new AuthService();

export default class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      error: null,
      page: null,
      loading: false
    }

    // Set up event handlers
    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
  }

  handleLoginSuccess(history, location) {
    let { from } = location.state || { from: { pathname: "/" } };
    history.replace(from);
  }

  handleQuery(query) {
    this.loadPage(query, 0);
  }

  loadPage(query, start) {
    if (query === '') {
      this.setState(() => ({ page: null }));
      return;
    }

    this.setState(() => ({ loading: true }));
    search(query, start)
        .then(res => {
            if (! res.ok) {
              this.setState(() => ({ error: "failed to perform the query, please try again.", loading: false }));
              return;
            }

            res.json().then(page => {
              this.setState(() => ({ page: page, loading: false }));
            });
        })
        .catch(err => {
            this.setState(() => ({ error: err, loading: false }));
        });
  }

  handlePrevPage() {
    const currentPage = this.state.page;
    const query = currentPage.query;
    const start = currentPage.start - currentPage.pageSize;
    
    this.loadPage(query, start);
  }

  handleNextPage() {
    const currentPage = this.state.page;
    const query = currentPage.query;
    const start = currentPage.start + currentPage.pageSize;

    this.loadPage(query, start);
  }

  render() {
    return (
      <Router>
        <div className="app">
          <h1 className="header">FHL Sessionlist Search</h1>
          <Switch>
            <PrivateRoute authService={authService} exact path="/">
              <div>
                <QueryForm onSubmit={this.handleQuery} />
                  { this.renderResults() }
              </div>
            </PrivateRoute>
            <Route path="/login" render={({ history, location}) => (
              /* handleLoginSuccess(history, location)*/
              <LoginForm authService={authService} onLoginSuccess={() => this.handleLoginSuccess(history, location)} />
            )}/>
          </Switch>
        </div>
      </Router>
    );
  }

  renderResults() {
    if (this.state.loading) {
      return (
        <Loader />
      )
    }

    if (this.state.error) { 
      return (
        <div className="search-error">{this.state.error}</div>
      )
    }

    if (this.state.page) { 
      return (
        <Results page={this.state.page} onPrevPage={this.handlePrevPage} onNextPage={this.handleNextPage} />
      )
    }

    return null;
  }
}