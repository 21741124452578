import React, { Component } from 'react';
import PropTypes from "prop-types";
import Document from "./Document"

export default class Results extends Component {
    constructor(props) {
        super(props);

        // Set up event handlers
        this.handlePrevPage = this.handlePrevPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
    }

    static propTypes = {
        onPrevPage: PropTypes.func.isRequired,
        onNextPage: PropTypes.func.isRequired,
        page: PropTypes.object.isRequired
    };

    handlePrevPage() {
        this.props.onPrevPage();
    }

    handleNextPage() {
        this.props.onNextPage();
    }

    render() {
        const page = this.props.page;

        if (page.found === 0) {
            return (
                <div className="results">
                    <h2>No results found</h2>
                </div>
            )
        }
                
        const hasPrevPage = page.start > 0;
        const hasNextPage = page.start + 10 < page.found;
        return (
            <div className="results">
                <h2>{page.found} results found</h2>
                {page.documents.map((document, i) => {
                                        return <Document key={document.filename} number={page.start + i + 1} document={document} />;
                                    })}

                <div className="navigation">
                    <button onClick={this.handlePrevPage} disabled={!hasPrevPage}>&lt;&lt; Previous</button>
                    <button onClick={this.handleNextPage} disabled={!hasNextPage}>Next &gt;&gt;</button>
                </div>
            </div>
        );
    }
}