import * as API from './API';

export default class AuthService {
    username = null;
  
    async isAuthenticated() {
        if (this.username != null) {
            return true;
        }

        return API.getCurrentUser()
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return {username: null};
        })
        .then(json => {
            this.username = json.username;
            return this.username != null;
        })
        .catch(() => {
            return false;
        });
    }

    login(username, password, onLoginSuccess, onLoginError) {
        API.login(username, password)
        .then(response => {
            if (response.ok) {
                onLoginSuccess();
            } else {
                onLoginError('failed to login');
            }
        })
        .catch(() => {
            onLoginError('failed to login');
        });
    }
}