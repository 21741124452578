import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class Hit extends Component {
    static propTypes = {
         hit: PropTypes.string.isRequired
    };

    render() {
        return (
            <div className="hit" dangerouslySetInnerHTML={{__html: this.props.hit}} />
        );
    }
}