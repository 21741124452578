import fetch from 'isomorphic-fetch';

/**
 * Generates a Fetch confiugration object so we can share headers
 * @method generateFetchConfig
 * @param  {string}            method      HTTP verb
 * @param  {object}            [body=null] payload for post/put
 * @return {object}                        config
 */
function generateFetchConfig(method, body = null) {
    const upCasedMethod = method.toUpperCase();
    const config = {
        method: upCasedMethod,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'same-origin'
    };
    if (['POST', 'PUT'].includes(upCasedMethod)) {
        config.body = JSON.stringify(body);
    }
    return config;
}


/**
 * Search for sessionlists, using given query
 * @method search
 * @param  {string} query The query
 * @param  {int} start the index of the first item of the page to fetch 
 * @return {Response} Fetch Response
 */
export function search(query, start) {
     return fetch(`api/search?query=${fixedEncodeURIComponent(query)}&start=${start}`, generateFetchConfig('GET'));
}

function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

/**
 * Get name of the authenticated user
 * @method getUserDetails
 * @return {Response} Fetch Response (the name or 403 when not authenticated)
 */
export function getCurrentUser() {
    return fetch('api/auth/currentUser', generateFetchConfig('GET'));
}

/**
 * Authenticate the user
 * @method login
 * @param  {string} username username
 * @param  {string} password password
 * @return {Response} Fetch Response (403 on authentication failure)
 */
export function login(username, password) {
    return fetch('api/auth/login', generateFetchConfig('POST', { username, password }));
}
