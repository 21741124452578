import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames'
import Hit from "./Hit"

export default class Document extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          collapsed: true
        }

        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    static propTypes = {
         number: PropTypes.number.isRequired,
         document: PropTypes.object.isRequired
    };

    toggleCollapse() {
        this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
    }

    render() {
        const document = this.props.document;
        const collapsed = this.state.collapsed;

        const collapseToggleClassNames = classNames (
            'collapse-toggle',
            'fa',
            {
                'fa-caret-up' : !collapsed,
                'fa-caret-down' : collapsed,                
            }
        )

        const hitsClassNames = classNames (
            'hits',
            {
                'collapsed' : collapsed               
            }
        )

        return (
            <div className="document">
                <div className="filename" onDoubleClick={this.toggleCollapse}>
                    <span className="documentNumber">{this.props.number}.</span>
                    <span>{document.filename} ({document.hits.length} hits)</span>
                    <i className={collapseToggleClassNames} onClick={this.toggleCollapse} />
                </div>
                <div className={hitsClassNames} >
                    {document.hits.map((hit, i) => {
                                            return <Hit key={i} hit={hit} />;
                                        })}
                </div>
            </div>
        );
    }
}